<template>
  <div class="full-line flex-row flex-wrap white-bg-color">
    <div class="full-line flex-row align-center" style="flex: 0 0 100%; height: 40px;">
      <div class="text-14 default-font-color" style="margin: 0 0 0 16px;">{{ title }}</div>
      <div v-if="necessary" class="text-14 red-font-color" style="margin-left: 5px;">*</div>
    </div>
    <div v-for="(person, index)  in persons" :key="index" class="person_item flex-row align-center justify-center" @click="deletePerson(index)">
      <div class="white-font-color text-14 flex-row align-center justify-center base-bg-color person_text">
        {{ person.name }}
      </div>
      <img v-if="!disabled" src="@/static/images/icon_person_delete.png" style="position: absolute;right: 0;top: 0;height: 15px;width: 15px;" />
    </div>
    <div v-if="!disabled" class="person_item" @click="refreshUser">
      <img src="@/static/images/icon_add_grey.png" style="position: absolute;top: 10%;left: 10%;height: 80%;width: 80%;" />
    </div>
    <Modal v-model="showSelect" :title=title @on-ok="okClick" @on-cancel="cancelClick">
      <cube-input v-model="keywords" clearable @input="input" class="full-line" placeholder="请输入关键字"></cube-input>
      <cube-scroll ref="scroll" :data="allPersons" class="full-line" style="height: 360px; flex: 0 0 360px;">
        <div v-for="(person, index) in allPersons" :key="index" class="full-line flex-row align-center" style="height: 50px;background-color: white;margin-bottom: 1px;">
          <cube-checkbox v-model="person.checked" class="full-line">
            <div class="text-14 default-font-color" style="margin-left: 5px;">{{ person.realName }}</div>
          </cube-checkbox>
        </div>
      </cube-scroll>
    </Modal>
  </div>
</template>

<script>
import { GetUser } from '@/api';

export default {
  name: "personSelect.vue",
  model: {
    prop: 'initialPersons',
    event: 'change'
  },
  props: {
    title: {
      type: String,
      default: "参与人"
    },
    necessary: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    initialPersons: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  data: function () {
    return {
      persons: this.initialPersons,
      showSelect: false,
      keywords: "",
      allPersons: []
    }
  },
  methods: {
    input: function () {
      this.usersRequest(() => {
        this.refreshUser();
      });
    },
    deletePerson: function (index) {
      if (!this.disabled) {
        this.persons.splice(index, 1);
        this.$emit('change', this.persons);
      }
    },
    okClick: function () {
      const selectedUserId = this.persons.map(person => person.id);
      const tempPersons = this.allPersons.filter(person => person.checked).map(person => {
        return {
          id: person.id,
          name: person.realName
        }
      });
      tempPersons.forEach(person => {
        if (!selectedUserId.includes(person.id)) {
          // console.log(person);
          this.persons.push({
            id: person.id,
            name: person.name
          });
          // console.log(this.persons);
        }
      })
      this.$emit('change', this.persons);
      this.showSelect = false;
    },
    cancelClick: function () {
      this.showSelect = false;
    },
    refreshUser: function () {
      const selectedUserId = this.persons.map(person => person.id);
      this.allPersons.forEach(person => {
        if (selectedUserId.includes(person.id)) {
          person.checked = true;
        } else {
          person.checked = false;
        }
      });
      this.showSelect = true;
    },
    usersRequest: function (callback = null) {
      GetUser({
        keyword: this.keywords,
        page: {
          pageIndex: 1,
          pageSize: 9999,
          orderBy: ""
        },
        userToken: {
          userId: this.$store.state.user.userId,
        },
      }).then(res => {
        if (res.code === 0) {
          res.data.list.forEach(item => item.checked = false);
          this.allPersons = res.data.list;
          if (callback) {
            callback();
          }
        }
      }).catch(err => {
        console.log(err);
      });
    }
  },
  mounted() {
    this.usersRequest();
  }
}
</script>

<style scoped>
.person_item {
  position: relative;
  flex: 0 0 20%;
  width: 20%;
  padding-top: 20%;
}

.person_text {
  position: absolute;
  top: 10%;
  left: 10%;
  height: 80%;
  width: 80%;
  border-radius: 6px;
}
</style>
